import {Component, Input, OnInit} from '@angular/core';
import {GoogleAdsService} from '../../../../../../service/google-ads.service';
import {MessageService} from 'primeng/api';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {CampaignAutomationSettingsModel} from '../../../../../../model/campaign-automation-settings.model';
import {EventEmitterService} from '../../../../../../service/event-emitter.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'auto-automation-account-settings',
  templateUrl: './automation-account-settings.component.html',
  styleUrls: ['./automation-account-settings.component.scss']
})
export class AutomationAccountSettingsComponent implements OnInit {
  @Input() accountId: number;
  @Input() userId: number;
  campaignAutomationSettingsFormGroup: UntypedFormGroup | null = null;
  oemPrograms: string[];
  initialFormValues: {
    stateAbbr: any;
    zipCode: any;
    oemProgram: any;
    usePremiumAssets: any;
    websiteUrl: any;
    city: any;
    dealerId: any;
    latitude: any;
    makeString: any;
    longitude: any
  } = null;
  campaignTypes: any = {
    'Performance Max - Branded': 1,
    'Performance Max - New Sales': 2
  };
  constructor(
    private googleAdsService: GoogleAdsService,
    private messageService: MessageService,
    private eventEmitterService: EventEmitterService
  ) { }

  /**
   * Method to initialize the component
   */
  async ngOnInit(): Promise<void> {
    this.oemPrograms = [
      'Standard',
      'Ford Direct',
      'Stellantis',
      'Honda',
      'Acura',
      'Strict'
    ];
    await this.buildCampaignAutomationSettingsFormGroup();
    await this.getSettingsForAccount();
  }

  /**
   * Method to build the form group for the campaign automation settings
   */
  async buildCampaignAutomationSettingsFormGroup(): Promise<void> {
    const pattern = '^((?!\\s\\s)(?!([a-zA-Z][.,;:?]\\S))(?!([0-9][.,;:?][a-zA-Z]))(?!([a-zA-Z] [.,;:?]))(?!(\\w . . \\w))(?!([!*+])).)*$';
    this.campaignAutomationSettingsFormGroup = new UntypedFormGroup({
      oemProgram: new UntypedFormControl('', [Validators.maxLength(20), Validators.required, Validators.pattern(pattern)]),
      dealerId: new UntypedFormControl('', [Validators.maxLength(100), Validators.required, Validators.pattern(pattern)]),
      makeString: new UntypedFormControl('', [Validators.maxLength(50), Validators.required, Validators.pattern(pattern)]),
      websiteUrl: new UntypedFormControl('', [Validators.maxLength(100), Validators.required, Validators.pattern(pattern)]),
      stateAbbr: new UntypedFormControl('', [Validators.maxLength(10), Validators.required, Validators.pattern(pattern)]),
      city: new UntypedFormControl('', [Validators.maxLength(100), Validators.required, Validators.pattern(pattern)]),
      zipCode: new UntypedFormControl('', [Validators.maxLength(10), Validators.required, Validators.pattern(pattern)]),
      latitude: new UntypedFormControl('', [Validators.maxLength(30), Validators.required, Validators.pattern(pattern)]),
      longitude: new UntypedFormControl('', [Validators.maxLength(30), Validators.required, Validators.pattern(pattern)]),
      usePremiumAssets: new UntypedFormControl(false)
    });
  }

  /**
   * Method to get the settings for the account
   */
  async getSettingsForAccount(): Promise<void> {
    this.googleAdsService.getAutomationAccountSettings(this.accountId).subscribe(
      async (response) => {
        await this.populateForm(response);
    }, async (error) => {
        await this.getSettingsForAccountFromMpop(true);
    });
  }

  /**
   * Method to get the settings for the account from MPOP
   * @param initialization
   */
  async getSettingsForAccountFromMpop(initialization: boolean): Promise<void> {
    this.googleAdsService.getAutomationAccountSettingsFromMpop(this.accountId).subscribe(
      async (response) => {
        this.campaignAutomationSettingsFormGroup.markAsDirty();
        await this.populateForm(response);
        if (initialization) {
          await this.createAccountSettings();
        }
        this.messageService.add({
          severity: 'success',
          summary: 'Synced Settings',
          detail: 'Your campaign automation settings synced from MPOP',
          life: 5000
        });
    }, (error) => {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Getting settings from MPOP failed',
        life: 5000
      });
    });
  }

  /**
   * Method to create the account settings
   */
  async createAccountSettings(): Promise<void> {
    const settings = this.createModelFromForm();
    this.googleAdsService.createAutomationAccountSettings(settings)
      .subscribe((response) => {
        this.campaignAutomationSettingsFormGroup.markAsPristine();
        this.messageService.add({
          severity: 'success',
          summary: 'Initialized Settings',
          detail: 'Your campaign automation settings were initialized',
          life: 5000
        });
      }, (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Campaign automation settings failed to initialize.',
          life: 5000
        });
      });
  }

  /**
   * Method to save the account settings
   */
  saveAccountSettings(): void {
    const settings = this.createModelFromForm();
    this.googleAdsService.updateAutomationAccountSettings(settings).subscribe(
      (response) => {
        this.campaignAutomationSettingsFormGroup.markAsPristine();
        this.eventEmitterService.emitEvent('getTextAssetValidation');
        this.messageService.add({
          severity: 'success',
          summary: 'Updated Settings',
          detail: 'Your campaign automation settings were updated',
          life: 5000
        });
      }, (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Campaign automation settings failed to update.',
          life: 5000
        });
      });
    this.queuePmaxUpdate();
  }

  /**
   * Method to queue the Performance Max update
   */
  queuePmaxUpdate(): void {
    this.googleAdsService.getAutomationConfigurationSettings(this.accountId).subscribe(
      (response) => {
        let campaignTypeIds = this.getPmaxCampaignTypeIds(response);
        let observables = [];
        campaignTypeIds.forEach((campaignTypeId) => {
          observables.push(this.googleAdsService.queuePmaxUpdate(this.accountId, campaignTypeId));
        });
        forkJoin(observables).subscribe({
          next: (results: any[]) => {
            results.forEach((result, index) => {
              if (result.message === 'The Performance Max Campaigns update process is in progress.') {
                this.messageService.add({
                  severity: 'info',
                  summary: 'In Progress',
                  detail: result.message,
                  life: 5000
                });
              } else {
                this.messageService.add({
                  severity: 'success',
                  summary: 'Process Started',
                  detail: 'Updates to Performance Max campaigns queued to process.',
                  life: 5000
                });
              }
            });
          },
          error: (error) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: error.message,
              life: 5000
            });
          }
        });
      }
    );
  }

  /**
   * Helper method that populates the form with the settings response values
   * @param settings
   */
  async populateForm(settings): Promise<void> {
    this.campaignAutomationSettingsFormGroup.controls.oemProgram.setValue(settings.oemProgram);
    this.campaignAutomationSettingsFormGroup.controls.dealerId.setValue(settings.dealerId);
    this.campaignAutomationSettingsFormGroup.controls.makeString.setValue(settings.makeString);
    this.campaignAutomationSettingsFormGroup.controls.websiteUrl.setValue(settings.websiteUrl);
    this.campaignAutomationSettingsFormGroup.controls.stateAbbr.setValue(settings.stateAbbr);
    this.campaignAutomationSettingsFormGroup.controls.city.setValue(settings.city);
    this.campaignAutomationSettingsFormGroup.controls.zipCode.setValue(settings.zipCode);
    this.campaignAutomationSettingsFormGroup.controls.latitude.setValue(settings.latitude);
    this.campaignAutomationSettingsFormGroup.controls.longitude.setValue(settings.longitude);
    this.campaignAutomationSettingsFormGroup.controls.usePremiumAssets.setValue(settings.usePremiumAssets);
    this.setInitialFormValues(settings);
  }

  /**
   * Sets the initial form values
   * @param settings
   */
  setInitialFormValues(settings): void {
    this.initialFormValues = {
      oemProgram: settings.oemProgram,
      dealerId: settings.dealerId,
      makeString: settings.makeString,
      websiteUrl: settings.websiteUrl,
      stateAbbr: settings.stateAbbr,
      city: settings.city,
      zipCode: settings.zipCode,
      latitude: settings.latitude,
      longitude: settings.longitude,
      usePremiumAssets: settings.usePremiumAssets
    };
  }

  /**
   * Method to create a model from the form
   */
  createModelFromForm(): CampaignAutomationSettingsModel {
    return new CampaignAutomationSettingsModel(
      this.accountId,
      this.campaignAutomationSettingsFormGroup.controls.oemProgram.value,
      this.campaignAutomationSettingsFormGroup.controls.dealerId.value,
      this.campaignAutomationSettingsFormGroup.controls.makeString.value,
      this.campaignAutomationSettingsFormGroup.controls.websiteUrl.value,
      this.campaignAutomationSettingsFormGroup.controls.stateAbbr.value,
      this.campaignAutomationSettingsFormGroup.controls.city.value,
      this.campaignAutomationSettingsFormGroup.controls.zipCode.value,
      this.campaignAutomationSettingsFormGroup.controls.latitude.value,
      this.campaignAutomationSettingsFormGroup.controls.longitude.value,
      this.campaignAutomationSettingsFormGroup.controls.usePremiumAssets.value,
      this.userId
    );
  }

  /**
   * Method to get the campaign type ids for Performance Max campaigns
   * @param campaignConfigurations
   * @returns number[]
   */
  getPmaxCampaignTypeIds(campaignConfigurations): number[] {
    let campaignTypeIds = [];
    campaignConfigurations.forEach((campaignConfiguration) => {
      const campaignName = campaignConfiguration.standardCampaignName;
      if (campaignName.includes('Performance Max') && campaignConfiguration.updatesEnabled) {
        let campaignTypeId = this.campaignTypes[campaignName];
        campaignTypeIds.push(campaignTypeId);
      }
    });

    return campaignTypeIds;
  }
}

<form [formGroup]="mediaFriendlyImageForm" autoTrackUnsavedChanges>
  <div class="tab-panel-content-wrapper">
    <div class="p-grid p-col-12 setting-content">
      <label class="field-switch-label"><b>Media Friendly Images:</b></label>
    </div>
    <div class="tab-panel-input-wrapper final-url-dropdown">
      <label class="label-field">Media Friendly Image (New Vehicles)
        <span class="pi pi-info-circle"
              pTooltip="This is used to determine which image should be sent to Google VLA and Facebook Ads as the new
               vehicle primary image. The main use is when the first image in the feed is disallowed due to excessive
                text/overlays. The number reflects the order of the images in the inventory file."
              tooltipPosition="top">
        </span>
      </label>
      <p-dropdown formControlName="newMediaFriendlyImage" optionLabel="label" [options]="newMediaImage"
                  [style]="{'min-width' : '8rem'}" optionValue="value"></p-dropdown>
    </div>
    <div class="tab-panel-input-wrapper final-url-dropdown">
      <label class="label-field">Media Friendly Image (Used Vehicles)
        <span class="pi pi-info-circle"
              pTooltip="This is used to determine which image should be sent to Google VLA and Facebook Ads as the used
               vehicle primary image. The main use is when the first image in the feed is disallowed due to excessive
                text/overlays. The number reflects the order of the images in the inventory file."
              tooltipPosition="top">
        </span>
      </label>
      <p-dropdown formControlName="usedMediaFriendlyImage" optionLabel="label" [options]="usedMediaImage"
                  [style]="{'min-width' : '8rem'}" optionValue="value"></p-dropdown>
    </div>
  </div>
</form>

<form [formGroup]="googleSettingsForm" autoTrackUnsavedChanges>
  <div class="tab-panel-content-wrapper">
    <div class="p-grid p-col-12 setting-content">
      <label class="field-switch-label"><b>Google Vehicle Listings</b>
        <span class="pi pi-info-circle tooltip-field"
              pTooltip="When enabled, the Vehicle Listings Feed will be active."
              tooltipPosition="top">
        </span>
      </label>
      <p-inputSwitch formControlName="carsForSaleEnabled"></p-inputSwitch>
    </div>
    <p-divider></p-divider>
    <div class="p-grid p-col-12 setting-content">
      <label class="field-switch-label"><b>Automated GBP Posts</b>
        <span class="pi pi-info-circle tooltip-field"
              pTooltip="Posts go to the Uberall ID with the correlating Google Store Code (or Store Code-S for service).
                 When no store code is present, the post will go to the Uberall ID that matches the MPOP ID (or MPOP ID-S for service).
                 The Google Store Code is used for GBP and VLA."
              tooltipPosition="top">
        </span>
      </label>
      <p-inputSwitch formControlName="gmbPostsEnabled"></p-inputSwitch>
    </div>
    <div class="tab-panel-input-wrapper final-url-dropdown">
      <label class="label-field">New Final URL</label>
      <p-dropdown [options]="finalUrlOptions" formControlName="gbpNewFinalUrl" optionLabel="label"
                  [style]="{'min-width' : '10rem'}" optionValue="value"></p-dropdown>
    </div>
    <div class="tab-panel-input-wrapper final-url-dropdown">
      <label class="label-field">Used Final URL</label>
      <p-dropdown [options]="finalUrlOptions" formControlName="gbpUsedFinalUrl" optionLabel="label"
                  [style]="{'min-width' : '10rem'}" optionValue="value"></p-dropdown>
    </div>
    <p-divider></p-divider>
    <div class="p-grid p-col-12 setting-content">
      <label class="field-input-label tab-input-label"><b>Google Store Code</b></label>
      <input type="text" pInputText class="text-input" formControlName="googleStoreCode"/>
    </div>
    <div class="tab-panel-input-wrapper final-url-dropdown">
      <label class="label-field field-switch-label">Use Dealer ID value as Google Store Code
        <span class="pi pi-info-circle"
              pTooltip="When enabled, the mapped and normalized DealerID value will replace the Google Store Code value
              per vehicle. This setting should only be used in instances where we need to show vehicles at multiple
              different city locations in our Google Vehicle Ads feed."
              tooltipPosition="top">
        </span>
      </label>
      <p-inputSwitch formControlName="useDealerId"></p-inputSwitch>
    </div>
    <p-divider></p-divider>
    <div class="p-grid p-col-12 setting-content">
      <label class="field-input-label tab-input-label"><b>Google Vehicle Ads Catalog URL</b></label>
      <input
        type="text"
        pInputText
        class="text-input"
        readonly
        formControlName="googleVlaFeedCatalogUrl"/>
      <i
        class="pi pi-fw pi-copy"
        (click)="copyToClipboardService.copyToClipboard(this.googleSettingsForm.controls.googleVlaFeedCatalogUrl.value)">
      </i>
    </div>
    <div class="tab-panel-input-wrapper final-url-dropdown">
      <label class="label-field field-switch-label">Advertise All Vehicles in Inventory
        <span class="pi pi-info-circle"
              pTooltip="When enabled, all vehicles in inventory will be exported in the Google Vehicle Ads feed.
                        When disabled, only active MPOP Ads will be exported in the feed."
              tooltipPosition="top">
        </span>
      </label>
      <p-inputSwitch formControlName="advertiseAllGoogleVehicles"></p-inputSwitch>
    </div>
    <div class="tab-panel-input-wrapper final-url-dropdown">
      <label class="label-field field-switch-label">Minimum Image Requirement
        <span class="pi pi-info-circle"
              pTooltip="Ads/Vehicles in inventory will be sent to GVA if they meet the minimum image requirement."
              tooltipPosition="top">
        </span>
      </label>
      <input type="number" pInputText class="minimum-image-requirement-field"
             formControlName="minimumImageRequirement"/>
    </div>
    <div class="button-spinner-container">
      <button
        pButton
        label="Upload Google Vehicle Ads Feed"
        pTooltip="Click to manually update the Google Vehicle Ads feed with the latest inventory and ad updates."
        tooltipPosition="right"
        (click)="uploadGoogleVehicleAdsFeed()"
        [disabled]="!googleSettingsForm.controls.googleStoreCode.value || uploadGvaFeedLoading"
        class="p-button-success populate-button">
      </button>
      <p-progressSpinner
        *ngIf="uploadGvaFeedLoading"
        [style]="{'height': '3rem'}"
        strokeWidth="8">
      </p-progressSpinner>
    </div>
    <small *ngIf="!googleSettingsForm.controls.googleStoreCode.value"
           class="p-d-block p-error block error-message-field">
      <i>Requires Google Store Code to be populated.</i>
    </small>
    <p-divider></p-divider>
    <div class="p-grid p-col-12 setting-content">
      <label class="field-input-label tab-input-label"><b>Google Analytics Measurement ID</b></label>
      <input type="text" pInputText class="text-input" formControlName="googleAnalyticsMeasurementId"/>
    </div>
    <div class="tab-panel-input-wrapper final-url-dropdown">
      <label class="label-field field-switch-label">Google Analytics Event Tracking
        <span class="pi pi-info-circle"
              pTooltip="When enabled, Google Analytics tracking will be initialized in the DT Media Tag."
              tooltipPosition="top">
        </span>
      </label>
      <p-inputSwitch formControlName="useGoogleAnalyticsTracking"></p-inputSwitch>
    </div>
    <div class="button-spinner-container">
      <button
        pButton
        label="Populate Google Analytics Property"
        pTooltip="This button will create all necessary custom dimensions and conversion events in the Google Analytics property."
        tooltipPosition="right"
        [disabled]="!googleSettingsForm.controls.googleAnalyticsMeasurementId.value || populatingGoogleAnalyticsProperty"
        (click)="populateGoogleAnalyticsProperty()"
        class="p-button-success populate-button">
      </button>
      <p-progressSpinner
        *ngIf="populatingGoogleAnalyticsProperty"
        [style]="{'height': '3rem'}"
        strokeWidth="8">
      </p-progressSpinner>
    </div>
    <small *ngIf="!googleSettingsForm.controls.googleAnalyticsMeasurementId.value"
           class="p-d-block p-error block error-message-field">
      <i>Requires Google Analytics Measurement ID to be populated.</i>
    </small>
  </div>
</form>

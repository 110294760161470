<div class="tab-panel-content-wrapper">
  <label class="field-input-label tab-input-label"><b>Media Tags Included:</b></label>
  <div *ngFor="let tag of includedMediaTags" class="included-tags-field">
    <label><i class="included-tags-label">{{ tag.platform }}</i><i class="pi pi-check"></i></label>
  </div>
  <p-divider></p-divider>
  <div class="generate-media-tag-button button-spinner-container">
    <button
      pButton
      label="Generate Media Tags"
      (click)="generateMediaTags()"
      class="p-button-success populate-button media-tag-button">
    </button>
    <p-progressSpinner
      *ngIf="generateMediaTagsInProgress"
      [style]="{'height': '3rem'}"
      strokeWidth="8">
    </p-progressSpinner>
  </div>
  <div class="media-tag-container">
    <label
      class="field-input-label tab-input-label"><b>Media Tags Script</b>
      <i class="pi pi-fw pi-copy" (click)="copyToClipboardService.copyToClipboard(compiledMediaTags)"></i>
    </label>
    <textarea pInputTextarea [ngModel]="compiledMediaTags" [autoResize]="true" readonly
              class="media-script-field"></textarea>
  </div>
  <p-divider></p-divider>
  <div class="generate-media-tag-button button-spinner-container">
    <button
      pButton
      label="Create Google Ads Conversions"
      (click)="createConversionForAccount()"
      class="p-button-success populate-button media-tag-button">
    </button>
    <p-progressSpinner
      *ngIf="createConversionInProgress"
      [style]="{'height': '3rem'}"
      strokeWidth="8">
    </p-progressSpinner>
  </div>
  <label class="field-input-label tab-input-label"><b>Google Ads Conversions:</b></label>
  <div *ngIf=" conversions == undefined || conversions.length == 0" class="conversion-label">
    <label class="field-input-label tab-input-label"><i>None</i></label>
  </div>
  <div *ngIf="conversions.length > 0">
    <div *ngFor="let conversion of conversions" class="conversion-label">
      <label><i>{{conversion.name}}</i></label>
    </div>
  </div>
</div>
